import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { firestore } from "../../firebase/firebaseConfig";
import { useSearchParams } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { UnitCardLarge } from "../SharedComponents/UnitCardLarge";

export const GameShared = (props) => {
    const [searchParams] = useSearchParams();
    const uuid = searchParams.get('uuid');
    const matchQuery = firestore.collection('matches').where('uuid', '==', uuid);
    const [mData, matchDataLoading, matchDataError] = useCollectionData(matchQuery);
    const matchData = mData && mData[0];
    const [unitA, unitB] = Object.keys(matchData?.selectedUnits || {}).sort().map(k => (matchData?.selectedUnits || {})[k]);
    const getAllUnitSelections = (unit) => {
        console.log(unit);
        if (!unit) return;
        let selections = {
            rangedWeapons: [],
            meleeWeapons: [],
            wargear: [],
        };
        unit.rangedWeapons.map(w => w.profiles[0].name).forEach(weapon => {
            selections.rangedWeapons.push(weapon.split('–')[0].split('-')[0]);
        });
        // iterate over melee weapons and update selections with defaults
        unit.meleeWeapons.map(w => w.profiles[0].name).forEach(weapon => {
            selections.meleeWeapons.push(weapon.split('–')[0].split('-')[0]);
        });
        unit?.abilities?.wargear?.map(wargear => wargear.name).forEach(wargear => {
            selections.abilities.push(wargear);
        });
        return selections;
    }
    const selectionsA = unitA ? getAllUnitSelections(unitA) : null;
    const selectionsB = unitB ? getAllUnitSelections(unitB) : null;
    console.log(selectionsA, selectionsB);
    return (
        <Container>
            <CardContainer>
                {
                    unitA && selectionsA &&
                    <UnitCardLarge unit={unitA} selections={selectionsA}/>
                }
            </CardContainer>
            <CardContainer>
                {
                    unitB && selectionsB &&
                    <UnitCardLarge unit={unitB} selections={selectionsB}/>
                }
            </CardContainer>
        </Container>
    )
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const CardContainer = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    box-sizing: border-box;
`;