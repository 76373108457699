import { Routes, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { firebaseAuth, firestore } from '../../firebase/firebaseConfig';
import { useEffect, useMemo, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Autocomplete, Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import styled from '@emotion/styled';
import { matchPhases, preGamePhases } from '../../utils/MatchDefinitions';
import { getAlignedFactions } from '../../utils/Factions';

export const GameIndividual = (props) => {
  const navigate = useNavigate();
  const [selectedList, setSelectedList] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const uid = firebaseAuth.currentUser.uid;
  const { gameId } = useParams();
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const matchQuery = firestore.collection('matches').where('uuid', '==', uuid);
  const listsQuery = firestore.collection('lists').where('user', '==', uid);
  const [mData, matchDataLoading, matchDataError] = useCollectionData(matchQuery);
  const [lData, listsDataLoading, listsDataError] = useCollectionData(listsQuery);
  const listsData = lData || [];
  const matchData = mData && mData[0];
  let factions = [];
  let mainFactionId;
  let selectedListData;
  if (selectedList) {
    selectedListData = listsData.find((list) => list.id === selectedList);
    if (selectedListData?.faction) {
      factions = [selectedListData.faction, ...getAlignedFactions(selectedListData?.faction)];
      mainFactionId = selectedListData.faction;
    }
  }
  const unitsRef = firestore.collection('units');
  const unitsQuery = factions.length > 0 ? unitsRef.where('faction_id', 'in', factions) : null;
  const [units] = useCollectionData(unitsQuery);

  const saveListSelection = (listId) => {
    if (!listId || !uuid) return;
    firestore.collection('matches').doc(uuid).update({
      lists: {
        ...matchData.lists,
        [uid]: listId,
      }
    }, {match: true});
  };

  const saveUnitSelection = (unitName) => {
    if (!unitName) return;
    const unitDef = units.find((u) => u.name === unitName);
    firestore.collection('matches').doc(uuid).update({
      selectedUnits: {
        ...matchData.selectedUnits,
        [uid]: unitDef,
      }
    }, {merge: true});
  }

  useEffect(() => {
    if (matchData?.lists?.[uid]) {
      setSelectedList(matchData.lists[uid]);
    }
    if (matchData?.selectedUnits?.[uid]) {
      setSelectedUnit(matchData.selectedUnits[uid]);
    }
  }, [matchData]);

  console.log(selectedListData);
  const filteredUnits = useMemo(() => {
    if (selectedListData && units) {
      return units.filter((u => Object.values(selectedListData.units || {}).map(a => a.name).includes(u.name)));
    }
    else return [];
  }, [units, selectedList])

  return (
    <Container>
      <Box sx={{minWidth: 120, margin: 1}}>
        <Autocomplete
          disablePortal
          id="list-select"
          options={listsData.map(list => ({label: list.name, value: list.id}))}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="List" />}
          onChange={(e, newVal) => { console.log(newVal.value); setSelectedList(newVal.value); saveListSelection(newVal.value); }}
        />
      </Box>
      {
        selectedList && units &&
        <Box sx={{minWidth: 120, margin: 1}}>
          <Autocomplete
            disablePortal
            id="unit-select"
            options={filteredUnits.map(unit => ({label: unit.name, value: unit.name}))}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Unit" />}
            onChange={(e, newVal) => { setSelectedUnit(newVal.value); saveUnitSelection(newVal.value) }}
          />
        </Box>
      }
    </Container>
  )
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const StyledSelect = styled(Select)`
    min-width: 250px;
    @media (max-width: 768px) {
        min-width: 100%;
        width: 100%;
    }
`;