import react from 'react';
import { firebaseAuth, gaProvider} from '../firebase/firebaseConfig';

export const SignInWithGoogle = () => {
    const signInWithGoogle = () => {
        firebaseAuth.signInWithPopup(gaProvider);
    }
    const signInAsGuest = () => {
        firebaseAuth.signInAnonymously();
    }

    return (
        <>
            <button onClick={signInAsGuest}>Sign In As Guest</button>
            <button onClick={signInWithGoogle}>Sign in with Google</button>
        </>
    );
}
