import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { firebaseAuth, firestore } from '../../firebase/firebaseConfig';
import AddModelsModal from './AddModelsModal';
import { useState } from 'react';
import { Button, Collapse } from 'antd';
import CollapsePanel from 'antd/es/collapse/CollapsePanel';
import styled from 'styled-components';
import { increment } from 'firebase/firestore';

export const Collection = () => {
  const { collectionId } = useParams();
  const uid = firebaseAuth.currentUser.uid;
  const collectionsRef = firestore.collection('collections');
  const query  = collectionsRef.where('id', '==', collectionId + ';' + uid);
  const [collectionMatch] = useCollectionData(query);
  const collection = collectionMatch?.[0];
  console.log(collection, collectionId);
  const [isAddModelsModalOpen, setIsAddModelsModalOpen] = useState(false);
  const openAddModelsModal = () => {
    setIsAddModelsModalOpen(true);
  };
  const closeAddModelsModal = () => {
    setIsAddModelsModalOpen(false);
  };
  const changeModelCount = (factionId, modelName, amount) => {
    const modelCount = collection.models[factionId][modelName];
    let newModelCount;
    console.log(modelCount, amount);
    if (Number(modelCount) + Number(amount) > 0) {
      newModelCount = Number(modelCount) + Number(amount);
    }
    else {
      // TODO: ask if user wants to delete model
    }
    // if newmodelcount is not falsy, set count to new value
    if (newModelCount) {
      collectionsRef.doc(collectionId + ';' + uid).set({
        models: {
          ...collection.models,
          [factionId]: {
            ...collection.models[factionId],
            [modelName]: newModelCount,
          },
        },
      }, {merge: true});
    }
    // if newmodelcount is falsy, delete model
    else {
      const newModels = JSON.parse(JSON.stringify(collection.models[factionId]));
      delete newModels[modelName];
      collectionsRef.doc(collectionId + ';' + uid).set({
        models: {
          ...collection.models,
          [factionId]: {
            ...newModels,
          },
        },
      }, {merge: true});
    }
  };
  console.log(collection);
  return (
    <div>
      <AddModelsModal
        open={isAddModelsModalOpen}
        closeModal={closeAddModelsModal}
        collectionKey={collectionId + ';' + uid}
      />
      <h1>Collection: {collection?.name}</h1>
      <button onClick={openAddModelsModal}>Add Models</button>
      {/* TODO: add logic to add factions */}
      {/* 
        TODO: add logic to display factions which are in collection
        - Expandable faction
        - List of models with ability to add/increment/decrement/remove
        - Models for adding pulled from:
            - filter `units` by factions
            - pull out all compositions
            - remove beginning numbers ("x" or "x-y") with regex
            - add to Set to determine unique models
      */}
      {
        collection?.models &&
        <Collapse>
          {
            Object.keys(collection.models || {})
            .filter(key => Object.keys(collection.models[key]).length)
            .sort((a, b) => a.localeCompare(b))
            .map(factionId => (
              <CollapsePanel header={factionId + ' - ' + Object.values(collection.models[factionId]).reduce((a, b) => a + b, 0) + ' models'} key={factionId}>
                <ModelsContainer>
                  {
                    Object.keys(collection.models[factionId] || {})
                    .sort((a, b) => a.localeCompare(b))
                    .map(modelName => (
                      <ModelEditor key={modelName}>
                        <Button
                          onClick={() => {
                            changeModelCount(factionId, modelName, -1);
                          }}
                          type="primary"
                          icon="-"
                          shape="circle"
                        />
                        <div>{modelName} x{collection.models[factionId][modelName]}</div>
                        <Button
                          onClick={() => {
                            changeModelCount(factionId, modelName, 1);
                          }}
                          type="primary"
                          icon="+"
                          shape="circle"
                        />
                      </ModelEditor>
                    ))
                  }
                </ModelsContainer>
              </CollapsePanel>
            ))
          }
        </Collapse>
      }
    </div>
  );
};

const ModelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const ModelEditor = styled.div`
  background: #eee;
  min-width: 200px;
  height: 40px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`;