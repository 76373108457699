import { Routes, Route } from 'react-router-dom';
import { Game } from './Game';
import { GamesHome } from './GamesHome';
import { GameIndividual } from './GameIndividual';
import { GameShared } from './GameShared';

export const GamesRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<GamesHome />} />
      <Route path="/:gameId" element={<Game />} />
      <Route path="/:gameId/play" element={<GameIndividual />} />
      <Route path="/:gameId/view" element={<GameShared />} />
    </Routes>
  );
};