
import { Button } from "antd";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { firestore } from "../../firebase/firebaseConfig";

export const ClickableCard = (props) => {
    const [listDefinitions, setListDefintions] = useState({});
    const listsRef = firestore.collection('lists');
    const {
        title,
        list,
        match,
        members,
        primaryColor="#8EA78D",
        secondaryColor='#A7998D',
        onClick,
        containerStyle={},
    } = props;

    const sortedMembers = members?.sort((a, b) => a.uid === match.createdBy ? -1 : 1);

    const memberOne = sortedMembers?.[0];
    const memberTwo = sortedMembers?.[1];
    const listOne = memberOne && listDefinitions[memberOne.uid];
    const listTwo = memberTwo && listDefinitions[memberTwo.uid];
    const listOneCost = listOne?.units ? Object.values(listOne.units).reduce((acc, unit) => {
        return acc + Number(unit.selections.cost) + Number(unit?.selections?.enhancement?.cost || 0);
      }, 0) : 0;
    const listTwoCost = listTwo?.units ? Object.values(listTwo.units).reduce((acc, unit) => {
        return acc + Number(unit.selections.cost) + Number(unit?.selections?.enhancement?.cost || 0);
      }, 0) : 0;
    const updateLists = async (userId, listId) => {
        const listDoc = await listsRef.doc(listId).get();
        setListDefintions((prev) => ({
            ...prev,
            [userId]: listDoc.data()
        }));
    }

    let primaryOverride = primaryColor;
    let secondaryOverride = secondaryColor;

    useEffect(() => {
        if (match) {
            const lists = match?.lists || {};
            if (Object.values(lists).length === 0) {
                setListDefintions({});
            }
            Object.keys(lists).forEach(userId => {
                updateLists(userId, lists[userId]);
            });
        }
        else {
            setListDefintions({});
        }
    }, [match]);

    let Content;
    if (list) {
        let cost = list?.units ? Object.values(list.units).reduce((acc, unit) => {
            return acc + Number(unit?.selections?.cost) + Number(unit?.selections?.enhancement?.cost || 0);
          }, 0) : 0;
        Content = (
            <>
                <HeadingContainer>{list?.factionLabel}</HeadingContainer>
                <SubHeadingContainer>{list?.detachment || '[Detachment]'}</SubHeadingContainer>
                <MediumHeadingContainer>{cost || 0} points</MediumHeadingContainer>
                <DescriptionContainer>{list?.description || ''}</DescriptionContainer>
            </>
        );
    }
    else if (match) {
        primaryOverride = '#aaaaaa';
        secondaryOverride = '#aaaaaa';
        Content = (
            <>
                <SplitContainer>
                    <HeadingContainer>{memberOne.displayName}</HeadingContainer>
                    <HeadingContainer>{memberTwo?.displayName}</HeadingContainer>
                </SplitContainer>
                <SplitContainer>
                    <SubHeadingContainer>{listOne?.name}</SubHeadingContainer>
                    <SubHeadingContainer>{listTwo?.name}</SubHeadingContainer>
                </SplitContainer>
                <SplitContainer>
                    <MediumHeadingContainer style={{flexDirection: 'column', justifyContent: 'flex-end'}}>{listOne?.factionName}</MediumHeadingContainer>
                    <MediumHeadingContainer style={{flexDirection: 'column', justifyContent: 'flex-end'}}>{listTwo?.factionName}</MediumHeadingContainer>
                </SplitContainer>
                <SplitContainer>
                    <MediumHeadingContainer style={{flexDirection: 'column', justifyContent: 'center'}}>{listOne?.detachment}</MediumHeadingContainer>
                    <MediumHeadingContainer style={{flexDirection: 'column', justifyContent: 'center'}}>{listTwo?.detachment}</MediumHeadingContainer>
                </SplitContainer>
                <SplitContainer>
                    <MediumHeadingContainer>{listOne ? `${listOneCost} points` : ''}</MediumHeadingContainer>
                    <MediumHeadingContainer>{listTwo ? `${listTwoCost} points` : ''}</MediumHeadingContainer>
                </SplitContainer>
            </>
        )
    }
    console.log(listOne, listTwo);
    return (
        <CardContainer
            secondary={listOne ? listOne.factionColors.banner : primaryOverride}
            primary={listTwo ? listTwo.factionColors.banner : secondaryOverride}
            onClick={onClick}
            style={containerStyle}
        >
            <ContentContainer hasContent={!!Content}>
                {Content || <></>}
            </ContentContainer>
            <TitleContainer hasContent={!!Content}>
                {title}
            </TitleContainer>
        </CardContainer>
    )
}

const CardContainer = styled(Button)`
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    min-width: 380px;
    min-height: 220px;
    max-width: 380px;
    max-height: 220px;
    @media (max-width: 600px) {
        min-width: calc(100% - 16px);
        min-height: 220px;
        max-width: 100%;
        max-height: 220px;
    }
    padding: 16px 32px;
    color: #fff;

    border-radius: 20px;
    background: linear-gradient(300deg, ${({primary}) => primary+'C0'} 25%, ${({secondary}) => secondary+'70'} 75%);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
    border: none;
`;

const ContentContainer = styled.div`
    height: ${({hasContent}) => hasContent ? 144 : 0}px;
    width: 100%;
`;

const SplitContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > * {
        max-width: calc(100% - ${({spacing}) => (spacing || 4)}px);
    }
`;

const HeadingContainer = styled.div`
    display: flex;
    height: 28px;

    text-align: ${({align}) => align || 'left'};
    color: #000;
    font-size: 18px;
    font-weight: bold;
`;

const SubHeadingContainer = styled.div`
    display: flex;
    height: 28px;

    text-align: ${({align}) => align || 'left'};
    color: #000;
    font-size: 16px;
    font-weight: bold;
`;

const MediumHeadingContainer = styled.div`
    display: flex;
    height: 28px;

    text-align: ${({align}) => align || 'left'};
    color: #000;
    font-size: 14px;
    font-weight: bold;
`;

const DescriptionContainer = styled.div`
    display: flex;

    text-align: ${({align}) => align || 'left'};
    color: #000;
    font-size: 12px;
    font-weight: bold;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: ${({hasContent}) => hasContent ? 44 : 188}px;
    width: 100%;

    text-align: right;
    font-size: 36px;
    font-weight: bold;
`;