import { Routes, Route } from 'react-router-dom';
import { ListViewer } from './ListViewer';
import { ListsHome } from './ListsHome';

export const ListsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ListsHome />} />
      <Route path="/:listId" element={<ListViewer />} />
    </Routes>
  );
};
