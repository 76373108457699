import { useState, useEffect } from 'react';
import { firebaseAuth, firestore } from '../../firebase/firebaseConfig';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import CreateMatchModal from './CreateMatchModal';
import { ClickableCard } from '../SharedComponents/ClickableCard';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

export const GamesHome = () => {
  const navigate = useNavigate();
  const matchesRef = firestore.collection('matches');
  const usersRef = firestore.collection('users');

  const matchesQuery = matchesRef.where('members', 'array-contains', String(firebaseAuth.currentUser.uid));
  const usersQuery = usersRef.where('isActive', '==', true);
  
  const [matches, isLoading, errorVal] = useCollectionData(matchesQuery, { idField: 'id' });
  const [users, isLoadingUsers, errorValUsers] = useCollectionData(usersQuery, { idField: 'id' });

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCreateMatch = (name, members) => {
    console.log(name, members);
    const uuid = crypto.randomUUID();
    matchesRef.doc(uuid).set({
      name: name.trim(),
      members: members,
      lists: {},
      uuid: uuid,
      createdBy: firebaseAuth.currentUser.uid,
    });
    setOpenModal(false);
  }

  return (
      <MatchesContainer>
        <CreateMatchModal
          open={openModal}
          onClose={handleCloseModal}
          handleCreateMatch={handleCreateMatch}
          users={users}
          matches={matches}
        />
        <ClickableCard
          onClick={handleOpenModal}
          containerStyle={{
            border: '2px dashed #0005',
            background: '#eee',
            color: '#000',
          }}
          title="+ Create Match"
        />
        {
          isLoading ? <p>Loading...</p> :
          <>
              {(matches || []).map((match) => (
              <ClickableCard
                key={match.uuid}
                match={match}
                members={users?.filter((user) => match.members.includes(user.uid)) || []}
                onClick={() => {
                  navigate(`/matches/${match.name}?uuid=${match.uuid}`);
                }}
                title={match.name}
              />
              ))}
          </>
        }
      </MatchesContainer>
    );
};

const MatchesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px;
  > * {
    margin: 8px;
  }
`;
