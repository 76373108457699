// boilerplate react component
import React, { useEffect, useState } from 'react';
import { firebaseAuth, firestore } from '../../firebase/firebaseConfig';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import CreateListModal from './CreateListModal';
import { FloatButton } from 'antd';
import styled from 'styled-components';
import { ClickableCard } from '../SharedComponents/ClickableCard';
import { useNavigate } from 'react-router-dom';
import { FileAddOutlined } from '@ant-design/icons';

export const ListsHome = () => {
  const navigate = useNavigate();
  const uid = firebaseAuth.currentUser.uid;
  const listsRef = firestore.collection('lists');
  const query  = listsRef.where('user', '==', uid);
  const factionsRef = firestore.collection('factions');
  const factionsQuery  = factionsRef.where('detachments', '!=', []);
  const [lists] = useCollectionData(query);
  const [factions] = useCollectionData(factionsQuery);
  const [newListModalOpen, setNewListModalOpen] = useState(false);
  const handleOpenModal = () => {
    setNewListModalOpen(true);
  };
  const handleCloseModal = () => {
    setNewListModalOpen(false);
  };

  return (
      <div>
          <CreateListModal
            open={newListModalOpen}
            closeModal={handleCloseModal}
            factions={factions}
          />
          {/* TODO: add search box */}
          <ListsContainer>
              <ClickableCard
                onClick={handleOpenModal}
                containerStyle={{
                  border: '2px dashed #0005',
                  background: '#eee',
                  color: '#000',
                }}
                title="+ Create New List"
              />
              {
                factions &&
                (lists || []).map((list) => {
                  const faction = factions.find(faction => faction.id === list.faction);
                  const detachment = faction?.detachments.length === 1 ? faction.detachments[0] : list.detachment;
                  return <ClickableCard
                    key={list.id}
                    onClick={() => { navigate(`/lists/${list.name}`) }}
                    list={{
                      ...list,
                      factionLabel: faction?.name,
                      detachment: detachment,
                    }}
                    title={list.name}
                    primaryColor={list.factionColors?.banner}
                    secondaryColor={list.factionColors?.header}
                  />
                })
              }
          </ListsContainer>
      </div>
  );
};

const ListsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px;
  > * {
    margin: 8px;
  }
`;