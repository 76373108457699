export const getPageTitle = (path) => {
    const matches = path.match(/^\/([^\/])+(\/[^\/]+)*$/);
    console.log(path, matches);
    if (path === '/') {
        return 'Home';
    }
    if (matches[2]) {
        return matches[2].slice(1).split('?')[0].replaceAll('%20', ' ');
    }
    else {
        return matches[0].charAt(1).toUpperCase() + matches[0].slice(2);
    }
}