import { MoreOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import styled from "styled-components";

export const UnitCardSmall = (props) => {
    const {
        unit,
        deleteUnit,
        handleEditUnit,
        handleOpenPreview,
    } = props;

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    }

    const openPreview = (unit) => {
        // TODO: make modal and datacard component
        handleOpenPreview(unit);
    }

    const baseCost = Number(unit.selections.cost)
    const models = Number(unit.selections.models);
    const enhancementCost = Number(unit?.selections?.enhancement?.cost || 0);

    return (
        <CardContainer onClick={handleExpandClick}>
            <ContentContainer expanded={expanded}>
                <NameContainer>
                    {models > 1 ? `${models}x ` : ''}{unit.name}{!expanded ? ` - ${Number(baseCost+enhancementCost)}` : ''}
                </NameContainer>
                {
                    !expanded ? <>
                        {
                            unit.selections.enhancement &&
                            <EnhancementContainer>
                                Enhancement: {unit.selections.enhancement?.name}
                            </EnhancementContainer>
                        }
                        <CostContainer>
                            Cost: {baseCost} {enhancementCost > 0 ? ` + ${enhancementCost}` : ''}
                        </CostContainer>
                        {
                            unit?.selections?.wargear?.length > 0 &&
                            <WargearContainer>
                                Wargear: {unit.selections.abilities.join(', ')}
                            </WargearContainer>
                        }
                    </> : <LargePoints>
                        {Number(baseCost+enhancementCost)}pts
                    </LargePoints>
                }
            </ContentContainer>
            <MoreMenu expanded={expanded}>
                {
                    expanded ?
                    <>
                        <Button onClick={() => deleteUnit(unit.unique_id)}>Delete</Button>
                        <Button onClick={() => openPreview(unit)}>Preview</Button>
                        <Button onClick={() => handleEditUnit(unit)}>Edit</Button>
                    </>
                    :
                    <MoreOutlined onClick={handleExpandClick}/>
                }
            </MoreMenu>
        </CardContainer>
    );
}

const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    background: linear-gradient(300deg, rgba(171, 171, 171, 0.75) 26.67%, rgba(0, 0, 0, 0.50) 100%);
    height: 100px;
    max-width: 380px;
    justify-content: space-between;
    margin: 4px;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    width: ${props => props.expanded ? '152px' : '320px'};
    min-width: ${props => props.expanded ? '152px' : '320px'};
    box-sizing: border-box;
`;

const NameContainer = styled.div`
    color: #FFF;
    font-family: sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    text-align: left;
    height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const EnhancementContainer = styled.div`
    color: #FFF;

    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const CostContainer = styled.div`
    color: #FFF;

    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const WargearContainer = styled.div`
    color: #FFF;

    font-family: sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const LargePoints = styled.div`
    color: #FFF;

    text-align: center;
    font-family: sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    width: 100%;
    height: 56px;
`;

const MoreMenu = styled.div`
    border-radius: 0px 20px 20px 0px;
    width: ${props => props.expanded ? '228px' : '60px'};
    min-width: ${props => props.expanded ? '228px' : '60px'};
    height: 100%;
    background: linear-gradient(270deg, #B7A3A3 0%, rgba(63, 63, 63, 0.25) 100%);
    color: #FFF;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`