import styled from "styled-components";

export const UnitCardLarge = (props) => {
    const {
        unit,
        selections,
        primaryColor="#424242"
    } = props; 

    console.log(unit, selections);
    return unit ?
        (
            <CardContainer>
                <Header color={primaryColor}>
                    <Title>{unit.name}</Title>
                    <StatContainer>
                        <Stat style={{marginLeft: 0}}>
                            <StatLabel>M</StatLabel>
                            <StatBlock color={primaryColor}>{unit?.stats?.[0].m}</StatBlock>
                        </Stat>
                        <Stat>
                            <StatLabel>T</StatLabel>
                            <StatBlock color={primaryColor}>{unit?.stats?.[0].t}</StatBlock>
                        </Stat>
                        <Stat>
                            <StatLabel>SV</StatLabel>
                            <StatBlock color={primaryColor}>{unit?.stats?.[0].sv}</StatBlock>
                        </Stat>
                        <Stat>
                            <StatLabel>W</StatLabel>
                            <StatBlock color={primaryColor}>{unit?.stats?.[0].w}</StatBlock>
                        </Stat>
                        <Stat>
                            <StatLabel>LD</StatLabel>
                            <StatBlock color={primaryColor}>{unit?.stats?.[0].ld}</StatBlock>
                        </Stat>
                        <Stat>
                            <StatLabel>OC</StatLabel>
                            <StatBlock color={primaryColor}>{unit?.stats?.[0].oc}</StatBlock>
                        </Stat>
                    </StatContainer>
                </Header>
                <div style={{"width": "100%", "height": 2, backgroundColor: '#FBFBFB'}}/>
                <ContentContainer>
                    <LeftContainer>
                        {
                            selections.rangedWeapons.length > 0 &&
                            <>
                                <WeaponHeader
                                    color={primaryColor}
                                    style={{
                                        borderRight: '2px solid #FBFBFB',
                                    }}
                                >
                                    <WeaponHeaderText style={{justifyContent: 'flex-start', flex: 5}}>Ranged Weapons</WeaponHeaderText>
                                    <WeaponHeaderText style={{justifyContent: 'center', flex: 2}}>Range</WeaponHeaderText>
                                    <WeaponHeaderText style={{justifyContent: 'center', flex: 1}}>A</WeaponHeaderText>
                                    <WeaponHeaderText style={{justifyContent: 'center', flex: 1}}>BS</WeaponHeaderText>
                                    <WeaponHeaderText style={{justifyContent: 'center', flex: 1}}>S</WeaponHeaderText>
                                    <WeaponHeaderText style={{justifyContent: 'center', flex: 1}}>AP</WeaponHeaderText>
                                    <WeaponHeaderText style={{justifyContent: 'center', flex: 1}}>D</WeaponHeaderText>
                                </WeaponHeader>
                                <WeaponStatsContainer color={primaryColor}>
                                    {
                                        selections.rangedWeapons.map((weapon, weaponIndex) => {
                                            const weaponDefinition = unit.rangedWeapons.find(rangedWeapon => rangedWeapon.profiles[0].name.startsWith(weapon));
                                            return (
                                                <>
                                                    {
                                                        weaponDefinition.profiles.length > 1 &&
                                                        <WeaponStatLine>
                                                            <WeaponStat style={{justifyContent: 'flex-start', flex: 5}}>
                                                                {weaponDefinition.profiles[0].name.split('–')[0]}
                                                            </WeaponStat>
                                                        </WeaponStatLine>
                                                    }
                                                    {
                                                        weaponDefinition.profiles.map(profile => (
                                                            
                                                                <WeaponStatLine key={weapon}>
                                                                    <WeaponStat style={{alignItems: 'flex-start', flex: 5, fontWeight: 'normal'}}>
                                                                        <span>{weaponDefinition.profiles.length > 1 ? ` - ${profile.name.split('–')[1]}` : profile.name}</span>
                                                                        {
                                                                            profile.keywords.length > 0 &&
                                                                            <b style={{textTransform: 'uppercase'}}> {profile.keywords.map(k => `[${k}]`).join(',')}</b>
                                                                        }
                                                                    </WeaponStat>
                                                                    <WeaponStat style={{justifyContent: 'center', flex: 2}}>
                                                                        {profile.range}
                                                                    </WeaponStat>
                                                                    <WeaponStat style={{justifyContent: 'center', flex: 1}}>
                                                                        {profile.attacks}
                                                                    </WeaponStat>
                                                                    <WeaponStat style={{justifyContent: 'center', flex: 1}}>
                                                                        {profile.skill}
                                                                    </WeaponStat>
                                                                    <WeaponStat style={{justifyContent: 'center', flex: 1}}>
                                                                        {profile.strength}
                                                                    </WeaponStat>
                                                                    <WeaponStat style={{justifyContent: 'center', flex: 1}}>
                                                                        {profile.ap}
                                                                    </WeaponStat>
                                                                    <WeaponStat style={{justifyContent: 'center', flex: 1}}>
                                                                        {profile.damage}
                                                                    </WeaponStat>
                                                                </WeaponStatLine>
                                                        ))
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </WeaponStatsContainer>
                            </>
                        }
                        {
                            selections.meleeWeapons.length > 0 &&
                            <>
                                <WeaponHeader
                                    color={primaryColor}
                                    style={{
                                        borderRight: selections.rangedWeapons.length > 0 ? 'none' : '2px solid #FBFBFB',
                                    }}
                                >
                                    <WeaponHeaderText style={{justifyContent: 'flex-start', flex: 5}}>Melee Weapons</WeaponHeaderText>
                                    <WeaponHeaderText style={{justifyContent: 'center', flex: 2}}>Range</WeaponHeaderText>
                                    <WeaponHeaderText style={{justifyContent: 'center', flex: 1}}>A</WeaponHeaderText>
                                    <WeaponHeaderText style={{justifyContent: 'center', flex: 1}}>BS</WeaponHeaderText>
                                    <WeaponHeaderText style={{justifyContent: 'center', flex: 1}}>S</WeaponHeaderText>
                                    <WeaponHeaderText style={{justifyContent: 'center', flex: 1}}>AP</WeaponHeaderText>
                                    <WeaponHeaderText style={{justifyContent: 'center', flex: 1}}>D</WeaponHeaderText>
                                </WeaponHeader>
                                <WeaponStatsContainer color={primaryColor}>
                                    {
                                        selections.meleeWeapons.map((weapon, weaponIndex) => {
                                            // TODO: fix issue with multi-profile weapons, see warlord titan's arioch power claw
                                            // TODO: fix styling with lots of weapon keywords, see aggressors' flamestorm gauntlets RANGED
                                            const weaponDefinition = unit.meleeWeapons.find(meleeWeapon => meleeWeapon.profiles[0].name.startsWith(weapon));
                                            console.log(weaponDefinition);
                                            return (
                                                <>
                                                    {
                                                        weaponDefinition.profiles.length > 1 &&
                                                        <WeaponStatLine>
                                                            <WeaponStat style={{alignItems: 'flex-start', flex: 5, fontWeight: 'normal'}}>
                                                                {weaponDefinition.profiles[0].name.split('–')[0]}
                                                            </WeaponStat>
                                                        </WeaponStatLine>
                                                    }
                                                    {
                                                        weaponDefinition.profiles.map(profile => (
                                                            
                                                                <WeaponStatLine key={weaponDefinition.profiles.length > 1 ? ` - ${profile.name.split('–')[1]}` : profile.name}>
                                                                    <WeaponStat style={{alignItems: 'flex-start', flex: 5, fontWeight: 'normal'}}>
                                                                        {weaponDefinition.profiles.length > 1 ? ` - ${profile.name.split('–')[1]}` : profile.name}
                                                                        {
                                                                            profile.keywords.length > 0 &&
                                                                            <b style={{textTransform: 'uppercase'}}> {profile.keywords.map(k => `[${k}]`).join(',')}</b>
                                                                        }
                                                                    </WeaponStat>
                                                                    <WeaponStat style={{justifyContent: 'center', flex: 2}}>
                                                                        {profile.range}
                                                                    </WeaponStat>
                                                                    <WeaponStat style={{justifyContent: 'center', flex: 1}}>
                                                                        {profile.attacks}
                                                                    </WeaponStat>
                                                                    <WeaponStat style={{justifyContent: 'center', flex: 1}}>
                                                                        {profile.skill}
                                                                    </WeaponStat>
                                                                    <WeaponStat style={{justifyContent: 'center', flex: 1}}>
                                                                        {profile.strength}
                                                                    </WeaponStat>
                                                                    <WeaponStat style={{justifyContent: 'center', flex: 1}}>
                                                                        {profile.ap}
                                                                    </WeaponStat>
                                                                    <WeaponStat style={{justifyContent: 'center', flex: 1}}>
                                                                        {profile.damage}
                                                                    </WeaponStat>
                                                                </WeaponStatLine>
                                                        ))
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </WeaponStatsContainer>
                            </>
                        }
                        {
                            unit?.abilities?.primarch?.length > 0 &&
                            <>
                                <AbilityHeader color={primaryColor}>
                                    {unit.abilities.primarch[0].name}
                                </AbilityHeader>
                                <AbilitiesContainer style={{fontSize: 10, borderRight: '2px solid ' + primaryColor}}>
                                    <ul style={{paddingLeft: 20}}>
                                        {
                                            unit.abilities.primarch[0].abilities.map(ability => (
                                                <li key={ability.name}>
                                                    {
                                                        <><b>{ability.name}: </b> {ability.description}</>
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </AbilitiesContainer>
                            
                            </>

                        }
                        <Spacer style={{borderRight: '2px solid ' + primaryColor}}/>
                        <KeywordsContainer style={{borderRight: '2px solid ' + primaryColor}}>
                            Keywords: <b>{unit.keywords.join(', ')}</b>
                        </KeywordsContainer>
                    </LeftContainer>
                    <RightContainer>
                        <AbilityHeader color={primaryColor}>
                            ABILITIES
                        </AbilityHeader>
                        {
                            unit?.abilities?.core.length > 0 &&
                            <AbilitiesContainer>
                                CORE: <b>{unit.abilities.core.join(', ')}</b>
                            </AbilitiesContainer>
                        }
                        {
                            unit?.abilities?.faction.length > 0 &&
                            <AbilitiesContainer>
                                FACTION: <b>{unit.abilities.faction.join(', ')}</b>
                            </AbilitiesContainer>
                        }
                        {
                            unit?.abilities?.other.length > 0 &&
                            unit.abilities.other.map(ability => (
                                <AbilitiesContainer>
                                    <b>{ability.name}:</b> {ability.description}
                                </AbilitiesContainer>
                            ))
                        }
                        {
                            unit?.abilities?.damaged?.showDamagedAbility &&
                            <>
                                <AbilityHeader color={primaryColor}>
                                    DAMAGED {unit.abilities.damaged.range}
                                </AbilityHeader>
                                <AbilitiesContainer>
                                    {unit.abilities.damaged.description}
                                </AbilitiesContainer>
                            </>
                        }
                        {
                            unit?.abilities?.invul?.showInvulnerableSave &&
                            <>
                                <AbilityHeader color={primaryColor}>
                                    INVULNERABLE SAVE - {unit.abilities.invul.value}{unit.abilities.invul.showInfo ? ' *' : ''}
                                </AbilityHeader>
                                {
                                    unit.abilities.invul.showInfo &&
                                    <AbilitiesContainer>
                                        {unit.abilities.invul.info}
                                    </AbilitiesContainer>
                                }
                            </>
                        }
                        <FactionKeywordsContainer>
                            Faction Keywords: <b>{unit.factions.join(', ')}</b>
                        </FactionKeywordsContainer>
                    </RightContainer>
                </ContentContainer>
            </CardContainer>
        ) : (<div>Loading</div>)
};

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    min-height: 480px;
    border-radius: 25px;
    background: #FBFBFB;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
`;

const Header = styled.div`
    min-height: 76px;
    border-radius: 25px 25px 0px 0px;
    background: ${props => props.color};
    display: flex;
    flex-direction: row;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    flex-wrap: wrap;
    width: 100%;
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 384px;
    height: 59px;
    color: #FFF;
    font-size: 32px;
    font-weight: bold;
    flex: 1;
`;

const StatContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 76px;
    width: 100%;
    flex: 1;
`;

const Stat = styled.div`
    height: 76px;
    width: 40px;
    margin-left: 16px;
    :first-child {
        margin-left: 0px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StatLabel = styled.div`
    width: 40px;
    height: 18px;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
`;

const StatBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #FBFBFB;
    color: ${props => props.color};
    text-align: center;
    font-size: 24px;
    font-weight: bold;
`;

const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: start;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 4;
    height: 100%;
`;

const WeaponHeader = styled.div`
    display: flex;
    height: 24px;
    width: 100%;
    flex-direction: row;
    background: ${props => props.color};
    justify-content: center;
    padding-left: 20px;
    box-sizing: border-box;
    border-right: 2px solid ${props => props.color};
`;

const WeaponHeaderText = styled.div`
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
`;

const WeaponStatsContainer = styled.div`
    display: flex;
    flex-direction: column;
    >:nth-child(even) {
        background: #D0D0D0;
    }
    border-right: 2px solid ${props => props.color}
`;

const WeaponStatLine = styled.div`
    display: flex;
    min-height: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    padding-left: 20px;
    box-sizing: border-box;
`;

const WeaponStat = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 12px;
    font-weight: bold;
`;

const Spacer = styled.div`
    width: 100%;
    flex: 1;
    box-sizing: border-box;
`;

const KeywordsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 30px;
    margin-bottom: 4px;
    margin-top: auto;
    font-size: 10px;
    padding-left: 16px;
    box-sizing: border-box;
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 3;
    height: 100%;
`;

const AbilityHeader = styled.div`
    display: flex;
    height: 24px;
    width: 100%;
    color: #FFF;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
   
    flex-direction: row;
    background: ${props => props.color};
    justify-content: center;
    align-items: center;
`;

const AbilitiesContainer = styled.div`
    padding: 4px 8px;
    font-size: 12px;
`;

const FactionKeywordsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 30px;
    margin-bottom: 4px;
    margin-top: auto;
    font-size: 10px;
    padding-left: 16px;
`;
