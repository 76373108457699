import { Modal } from "@mui/material";
import { UnitCardLarge } from "./UnitCardLarge";
import styled from "@emotion/styled";

export const UnitCardModal = (props) => {
    const {
        unit,
        selections,
        open,
        closeModal,
    } = props;

    return (
        <StyledModal
            open={open}
            onClose={closeModal}
        >
            <UnitCardLarge unit={unit} selections={selections} />
        </StyledModal>
    )
}

const StyledModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px;
`;