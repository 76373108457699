import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Input, Select } from 'antd';
import { firebaseAuth, firestore } from '../../firebase/firebaseConfig';

const CreateListModal = (props) => {
  const listsRef = firestore.collection('lists');
  const {
    open,
    closeModal,
    factions,
  } = props;
  const [name, setName] = useState('');
  const [selectedFaction, setSelectedFaction] = useState(null);
  const [selectedDetachment, setSelectedDetachment] = useState(null);

  const handleSubmit = () => {
    if (!name || !selectedFaction || !selectedDetachment ) {
      // TODO: add error handling
      return;
    }
    const uid = firebaseAuth.currentUser.uid;
    listsRef.doc(name.trim()+';'+uid).set({
      name: name.trim(),
      user:uid,
      id: name.trim()+';'+uid,
      faction: selectedFaction,
      factionName: factions.find((faction) => faction.id === selectedFaction).name,
      factionColors: factions.find((faction) => faction.id === selectedFaction).colours,
      detachment: typeof selectedDetachment === 'string' ? selectedDetachment : selectedDetachment.label,
    });
    closeModal?.();
    setName('');
  };

  const factionOptions = useMemo(() => {
    if (factions) {
      return factions.map((faction) => ({
        label: faction.name,
        value: faction.id,
      })).sort(
        (a, b) => a.label.localeCompare(b.label),
      );
    }
    return [];
  }, [factions]);

  const detachmentOptions = useMemo(() => {
    if (selectedFaction && factions) {
      const factionEntry = factions.find((faction) => faction.id === selectedFaction);
      console.log(factionEntry, factions, selectedFaction);
      return factionEntry.detachments.map((detachment) => ({
        label: detachment,
        value: detachment,
      })).sort(
        (a, b) => a.label.localeCompare(b.label),
      );
    }
    return [];
  }, [selectedFaction, factions])

  useEffect(() => {
    if (detachmentOptions?.length === 1) {
      setSelectedDetachment(detachmentOptions[0]);
    }
  }, [detachmentOptions]);

  return (
    <Modal
      title="Create a list"
      open={open}
      onCancel={() => {closeModal?.(); setName('');}}
      onOk={handleSubmit}
    >
      <Input
        placeholder="Enter a name for your list"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Select
        showSearch
        optionFilterProp="label"
        placeholder="Select a faction"
        value={selectedFaction}
        onChange={(value) => {
          setSelectedDetachment(null);
          setSelectedFaction(value);
        }}
        options={factionOptions.sort((a, b) => a.label.localeCompare(b.label))}
        style={{ width: '100%', marginTop: '1rem' }}
      />
      {
        selectedFaction &&
        <Select
          showSearch
          optionFilterProp="label"
          placeholder="Select a detachment"
          value={selectedDetachment}
          onChange={(value) => setSelectedDetachment(value)}
          options={detachmentOptions}
          style={{ width: '100%', marginTop: '1rem' }}
          disabled={factionOptions.length === 1}
        />
      }
    </Modal>
  );
};

export default CreateListModal;
