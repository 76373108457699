import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyC759xSooiH7H9FAZVk244_WxJent0_Hn8",
    authDomain: "fortyplay.firebaseapp.com",
    projectId: "fortyplay",
    storageBucket: "fortyplay.appspot.com",
    messagingSenderId: "326081948521",
    appId: "1:326081948521:web:4f63b05e362405bea7ebf8",
    measurementId: "G-9ZZYBZ481D"
  };
  
  // Initialize Firebase
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  
  const gaProvider = new firebase.auth.GoogleAuthProvider();
  const firebaseAuth = firebase.auth();
  const firestore = firebase.firestore();
  
  export { firebase, firebaseAuth, gaProvider, firebaseApp, firestore };