// TODO: don't hardcode this
export const factionChoices = [
    {
        "label": "Genestealer Cults",
        "value": "GSC",
        "aligned": ["AM"],
    },
    {
        "label": "Necrons",
        "value": "NEC"
    },
    {
        "label": "Aeldari",
        "value": "AE",
        "aligned": ["DRU"],
    },
    {
        "label": "World Eaters",
        "value": "WE",
        "aligned": ["CD", "QT"],
    },
    {
        "label": "Death Watch",
        "value": "CHDW",
        "aligned": ["SM", "AoI", "QI", "AT"],
    },
    {
        "label": "Space Wolves",
        "value": "CHSW",
        "aligned": ["SM", "AoI", "QI", "AT"],
    },
    {
        "label": "Astra Militarum",
        "value": "AM",
        "aligned": ["AoI"],
    },
    {
        "label": "Thousand Sons",
        "value": "TS",
        "aligned": ["CD", "QT"],
    },
    {
        "label": "Chaos Daemons",
        "value": "CD",
        "aligned": ["QT"],
    },
    {
        "label": "Space Marines",
        "value": "SM",
        "aligned": ["AoI", "QI", "AT"],
    },
    {
        "label": "Adepta Sororitas",
        "value": "AS",
        "aligned": ["AoI", "QI", "AT"],
    },
    {
        "label": "Tyranids",
        "value": "TYR"
    },
    {
        "label": "T'au Empire",
        "value": "TAU"
    },
    {
        "label": "Imperial Knights",
        "value": "QI"
    },
    {
        "label": "Votann",
        "value": "LoV"
    },
    {
        "label": "Death Guard",
        "value": "DG",
        "aligned": ["CD", "QT"],
    },
    {
        "label": "Adeptus Mechanicus",
        "value": "AdM",
        "aligned": ["AoI", "QI", "AT"],
    },
    {
        "label": "Drukhari",
        "value": "DRU"
    },
    {
        "label": "Black Templar",
        "value": "CHBT"
    },
    {
        "label": "Adeptus Custodes",
        "value": "AC",
        "aligned": ["AoI", "QI", "AT"],
    },
    {
        "label": "Chaos Space Marines",
        "value": "CSM",
        "aligned": ["CD", "QT"],
    },
    {
        "label": "Blood Angels",
        "value": "CHBA",
        "aligned": ["SM", "AoI", "QI", "AT"],
    },
    {
        "label": "Grey Knights",
        "value": "GK",
        "aligned": ["AoI", "QI", "AT"],
    },
    {
        "label": "Chaos Knights",
        "value": "QT"
    },
    {
        "label": "Dark Angels",
        "value": "CHDA"
    },
    {
        "label": "Orks",
        "value": "ORK"
    },
    {
        "label": "Adeptus Titanicus",
        "value": "AT"
    },
    {
        "label": "Agents of the Imperium",
        "value": "AoI",
        "hideMainFactions": true,
    },
    {
        "label": "Unaligned",
        "value": "UN",
        "hideMainFactions": true,
    }
  ]

  export const getAlignedFactions = (factionId) => {
    const faction = factionChoices.find(faction => faction.value === factionId);
    return faction?.aligned || [];
  }
  