import { Routes, Route } from 'react-router-dom';
import { Collection } from './Collection';
import { CollectionsHome } from './CollectionsHome';

export const CollectionRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<CollectionsHome />} />
      <Route path="/:collectionId" element={<Collection />} />
    </Routes>
  );
};
