import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { firebaseAuth, firestore } from '../../firebase/firebaseConfig';
import { useEffect, useMemo, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Button, Select } from 'antd';
import styled from 'styled-components';
import { ClickableCard } from '../SharedComponents/ClickableCard';

export const Game = () => {
  const navigate = useNavigate();
  const { gameId } = useParams();
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');

  return (
    <Container>
      <ClickableCard
        title="Play (Individual)"
        onClick={() => { navigate(`/matches/${gameId}/play?uuid=${uuid}`); }}
      />
      <ClickableCard
        title="Play (Shared)"
        onClick={() => { navigate(`/matches/${gameId}/view?uuid=${uuid}`); }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

