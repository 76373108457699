import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { firebaseAuth, firestore } from '../../firebase/firebaseConfig';

const CreateCollectionModal = (props) => {
  const collectionsRef = firestore.collection('collections');
  console.log(props);
  const { open, closeModal } = props;
  const [name, setName] = useState('');

  const handleSubmit = () => {
    // TODO: Add code to create a collection with the given name.
    const uid = firebaseAuth.currentUser.uid;
    collectionsRef.doc(name+';'+uid).set({
      name,
      user:uid,
      id: name+';'+uid,
    });
    closeModal?.();
    setName('');
  };

  console.log(open);
  return (
    <Modal
      title="Create a collection"
      open={open}
      onCancel={() => {closeModal?.(); setName('');}}
      onOk={handleSubmit}
    >
      <Input
        placeholder="Enter a name for your collection"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </Modal>
  );
};

export default CreateCollectionModal;
