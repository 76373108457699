import React, { useMemo, useState } from "react";
import { firebaseAuth, firestore } from "../../firebase/firebaseConfig";

const AddEnhancements = () => {
  const enhancementsRef = firestore.collection('enhancements');

  const [rawJsonData, setRawJsonData] = useState({});

  const jsonData = useMemo(() => {
    try {
        const data = JSON.parse(rawJsonData);
        console.log(data);
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
  }, [rawJsonData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!Array.isArray(jsonData)) {
      alert('Malformed text');
      return;
    }
    const batch = firestore.batch();
    for (let i = 0; i < jsonData.length; i++) {
      const {faction_id} = jsonData[i];
      batch.set(enhancementsRef.doc(faction_id), jsonData[i]);
    }
    batch.commit();
    alert('updated enhacements');
  };

  return (
    <form onSubmit={handleSubmit}>
      <textarea
        value={rawJsonData}
        onChange={(e) => {setRawJsonData(e.target.value)}}
      />
      <div>
        Add or update content by pasting enhancements from here: <a href="https://raw.githubusercontent.com/game-datacards/datasources/main/10th/enhancements/enhancements.json">click here</a>
      </div>
      {
        jsonData && (
          <div>
            <h1>{jsonData.name}</h1>
            <h2>Add/update {jsonData?.datasheets?.length} factions enhancements</h2>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <div
                style={{
                  width: 30,
                  height: 30,
                  backgroundColor: jsonData?.colours?.banner ?? '#fff'
                }}
              />
              <div
                style={{
                  width: 30,
                  height: 30,
                  backgroundColor: jsonData?.colours?.header ?? '#fff'
                }}
              />
            </div>
          </div>
        )
      }
      <button type="submit" disabled={!jsonData?.length}>Submit</button>
    </form>
  );
};

export default AddEnhancements;
