import { useState, useEffect } from 'react';
import { firebaseAuth, firestore } from '../../firebase/firebaseConfig';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import CreateCollectionModal from './CreateCollectionModal';

export const CollectionsHome = () => {
  const collectionsRef = firestore.collection('collections');
  const userId = firebaseAuth.currentUser.uid;
  const query = collectionsRef.where('user', '==', userId);
  const [collections] = useCollectionData(query, { idField: 'id' });
  const [newCollectionModalOpen, setNewCollectionModalOpen] = useState(false);
  console.log(collections);
  const handleOpenModal = () => {
    setNewCollectionModalOpen(true);
  };
  const handleCloseModal = () => {
    setNewCollectionModalOpen(false);
  };

  if (collections?.length && collections.length !== 0) {
    return (
        <div>
            <CreateCollectionModal open={newCollectionModalOpen} closeModal={handleCloseModal} />
            <h1>Your collections:</h1>
            <ul>
                {collections.map((collection) => (
                <li key={collection.id}>
                    <a href={`/collection/${collection.name}`}>{collection.name}</a>
                </li>
                ))}
            </ul>
            <div>
                    <button onClick={handleOpenModal}>Create new collection</button>
            </div>
        </div>
    );
  }
  return (
    <div>
        <CreateCollectionModal open={newCollectionModalOpen} closeModal={handleCloseModal} />
        <h1>You do not own any collections.</h1>
            <div>
                    <button onClick={handleOpenModal}>Create new collection</button>
            </div>
    </div>
  );
};
