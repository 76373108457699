import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ClickableCard } from './SharedComponents/ClickableCard';
import styled from 'styled-components';

export const Home = () => {
  const navigate = useNavigate();
  return (
    <HomeContainer>
      <ClickableCard
        title="Collection"
        onClick={() => { navigate('/collection'); }}
      />
      {/* <ClickableCard
        title="Manage Data"
        onClick={() => { navigate('/manageData'); }}
      /> */}
      <ClickableCard
        title="Lists"
        onClick={() => { navigate('/lists'); }}
        primaryColor='#A78DA1'
        secondaryColor='#8FA78D'
      />
      <ClickableCard
        title="Matches"
        onClick={() => { navigate('/matches'); }}
        primaryColor='#60738F'
        secondaryColor='#3E9636'
      />
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  > * {
    margin: 8px;
  }
`;