import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Input, Select, Checkbox, Form, Radio } from 'antd';
import styled from 'styled-components';

const AddEditUnitModal = (props) => {
  const {
    isAddModal,
    open,
    closeModal,
    units,
    addUnitFunc,
    editUnitFunc,
    editUnitDef,
    hideLegends=true,
    enhancements,
    mainFactionId,
  } = props;
  const [unitNameToAdd, setUnitNameToAdd] = useState('');
  const [unitToAdd, setUnitToAdd] = useState(null);
  const [selections, setSelections] = useState(null);
  const [form] = Form.useForm();

  const displayUnits = useMemo(() => {
    return units?.filter(u => {
      if (hideLegends) {
        return !u.legends;
      }
      return true;
    })?.map(unit => {
      return {
        value: unit.name,
        label: unit.name,
      }
    }
  );
  }, [units]);

  useEffect(() => {
    if (editUnitDef) {
      const {rangedWeapons, meleeWeapons, abilities, cost, models, enhancement} = editUnitDef.selections;
      const weapons = {};
      (rangedWeapons || []).forEach((weapon) => {
        weapons[`ranged_${weapon}`] = true;
      });
      (meleeWeapons || []).forEach((weapon) => {
        weapons[`melee_${weapon}`] = true;
      });
      const abilitiesNew = {};
      (abilities || []).forEach((ability) => {
        abilities[`abilites_${ability}`] = true;
      });
      const points = {
        points: 'points_' + cost + '_' + models,
      };
      const enhancementNew = {};
      if (enhancement) {
        enhancementNew.enhancement = enhancement.name;
      }

      const newUnitToAdd = units.find((unit) => unit.name === editUnitDef.name);
      setUnitToAdd(newUnitToAdd);
      setUnitNameToAdd(editUnitDef.name);
      setSelections(editUnitDef.selections);
      form.setFieldsValue({
        ...weapons,
        ...abilitiesNew,
        ...points,
        ...enhancementNew,
      });
    }
  }, [editUnitDef]);

  const clearModal = () => {
    setUnitToAdd(null);
    setSelections(null);
    form.resetFields();
    setUnitNameToAdd('');
  }

  const handleUnitChanged = (value) => {
    console.log(value);
    const newUnit = units.find(unit => unit.name === value);
    console.log(newUnit);
    form.resetFields();
    setUnitNameToAdd(value);
    setUnitToAdd(newUnit);
    setSelections(null);
    updateDefaultLoadout(newUnit);
  }

  const updateDefaultLoadout = (unit) => {
    const loadouts = unit.loadout;
    const loadoutEntries = loadouts.split('.');
    let defaultLoadouts = [];
    loadoutEntries.forEach(loadout => {
      if (loadout === '') {
        return;
      }
      const defaults = loadout.substring(loadout.indexOf(':') + 1);
      const splitDefaults = defaults.split(';');
      const cleanedDefaults = splitDefaults.map(d => {
        const trimmed = d.trim();
        let singularized = trimmed.replace(/^\d+ /, '');
        if (singularized.length < trimmed.length) {
          singularized = singularized.replace(/s$/, '');
        }
        return singularized;
      });
      defaultLoadouts = [...defaultLoadouts, ...cleanedDefaults];
    });

    // iterate over ranged weapons and update selections with defaults
    unit.rangedWeapons.map(w => w.profiles[0].name).forEach(weapon => {
      const defaultWeapon = defaultLoadouts.find(loadout => weapon.toLowerCase().startsWith(loadout.toLowerCase()));
      console.log(weapon, defaultWeapon);
      if (defaultWeapon) {
        form.setFieldsValue({
          [`ranged_${weapon.split('–')[0].split('-')[0]}`]: true,
        });
      }
    });
    // iterate over melee weapons and update selections with defaults
    unit.meleeWeapons.map(w => w.profiles[0].name).forEach(weapon => {
      const defaultWeapon = defaultLoadouts.find(loadout => weapon.toLowerCase().startsWith(loadout.toLowerCase()));
      if (defaultWeapon) {
        form.setFieldsValue({
          [`melee_${weapon.split('-')[0].split('–')[0]}`]: true,
        });
      }
    });
    unit?.abilities?.wargear?.map(wargear => wargear.name).forEach(wargear => {
      const defaultWargear = defaultLoadouts.find(loadout => wargear.toLowerCase().startsWith(loadout.toLowerCase()));
      if (defaultWargear) {
        form.setFieldsValue({
          [`ability_${wargear}`]: true,
        });
      }
    });
    handleSelectionsChanged(unit);
  }

  const handleSelectionsChanged = (unitOverride) => {
    const newUnit = unitOverride || unitToAdd;
    const allValues = form.getFieldsValue(true);

    const rangedWeapons = [];
    Object.keys(allValues).filter(v => v.startsWith('ranged_')).forEach(v => {
      if (allValues[v]) {
        rangedWeapons.push(v.substring(v.indexOf('_') + 1));
      }
    });
    const meleeWeapons = [];
    Object.keys(allValues).filter(v => v.startsWith('melee_')).forEach(v => {
      if (allValues[v]) {
        meleeWeapons.push(v.substring(v.indexOf('_') + 1));
      }
    });
    const abilities = [];
    Object.keys(allValues).filter(v => v.startsWith('ability_')).forEach(v => {
      if (allValues[v]) {
        abilities.push(v.substring(v.indexOf('_') + 1));
      }
    });
    let [_, cost, models] = allValues.points?.split('_') || [null, null, null];

    const hasWeapons = rangedWeapons.length > 0 || meleeWeapons.length > 0;
    const hasAbilities = abilities.length > 0;
    console.log(JSON.parse(JSON.stringify(newUnit)));
    const singleCost = newUnit?.points?.length === 1;
    console.log(newUnit);
    if (singleCost) {
      cost = newUnit?.points[0]?.cost;
      models = newUnit?.points[0]?.models;
    }
    const hasPoints = cost && models;

    const newSelections = {
      rangedWeapons,
      meleeWeapons,
      abilities,
      cost,
      models,
    };

    if (allValues.enhancement) {
      newSelections.enhancement = enhancements.find(enhancement => enhancement.name ===allValues.enhancement);
    }

    console.log(newSelections, hasWeapons, hasPoints);
    if (hasWeapons && hasPoints) {
      setSelections(newSelections);
    }
    else {
      setSelections(null);
    }
  }

  const handleSubmit = () => {
    if (isAddModal) {
      addUnitFunc(unitToAdd, selections);
    }
    else {
      editUnitFunc(unitToAdd, selections, editUnitDef.unique_id);
    }
    closeModal();
    clearModal();
  };

  const deselectEnhancements = (e) => {
    console.log('HERE', e.target.name);
    form.setFieldsValue({
      enhancement: undefined,
    });
  }

  return (
    <Modal
      title={`${isAddModal ? 'Add' : 'Edit'} Unit`}
      open={open}
      onCancel={() => { closeModal(); clearModal(); }}
      onOk={handleSubmit}
      okButtonProps={{disabled: !selections}}
    >
      <Select
        showSearch
        optionFilterProp="label"
        placeholder="Select unit"
        value={unitNameToAdd}
        onChange={handleUnitChanged}
        options={displayUnits?.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        })}
        style={{width: '100%'}}
      />
      {
        unitToAdd &&
        <SelectionsContainer>
          <Form
            onFieldsChange={() => { handleSelectionsChanged(); }}
            form={form}
          >
            <h1>{unitToAdd.name}</h1>
            {
              unitToAdd.faction_id === mainFactionId && unitToAdd?.keywords?.includes('Character') && !unitToAdd.composition.some(c => c.indexOf('Epic Hero') > -1) &&
              <>
                <p><strong>Enhancements</strong></p>
                {
                  enhancements.filter(enhancement => {
                    const excluded = [...unitToAdd.keywords, ...unitToAdd.factions].some(keyword => {
                      return enhancement?.exclusions?.includes(keyword);
                    });
                    const keywordMatch = (!enhancement?.keywords || enhancement.keywords.length === 0) || [...unitToAdd.keywords, ...unitToAdd.factions].some(keyword => {
                      return enhancement?.keywords?.includes(keyword);
                    });
                    return !excluded && keywordMatch;
                  }).map(enhancement => {
                    const descSplit = enhancement.description.split('■');
                    const descStrings = descSplit.length > 1 ? descSplit.slice(1) : descSplit;
                    return (
                      <Form.Item name="enhancement">
                        <Radio.Group>
                          <Radio onClick={deselectEnhancements} value={enhancement.name}>
                            <b>{enhancement.name} |</b> {enhancement.cost} pts {enhancement.detachment ? <b>{`| ${enhancement.detachment}`}</b> : ''}
                            <ul>
                              {
                                descStrings.map(desc => (
                                  <li key={desc}>{desc}</li>
                                ))
                              }
                            </ul>
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    );
                  })
                }
              </>
            }
            <p><strong>Default Loadout</strong></p>
            <p>{unitToAdd.loadout}</p>
            {
              unitToAdd.rangedWeapons && unitToAdd.rangedWeapons.length > 0 &&
              <>
                <p><strong>Select Ranged Weapons</strong></p>
                  {
                    unitToAdd.rangedWeapons?.map(weapon => {
                      return weapon.profiles.length > 1 ? (
                          <><Form.Item valuePropName='checked' style={{marginBottom: 0}} name={'ranged_' + weapon.profiles[0].name.split('–')[0].split('-')[0]}><Checkbox>{weapon.profiles[0].name.split('–')[0].split('-')[0]}</Checkbox></Form.Item><ul style={{marginTop: 0}}>
                            {
                              weapon.profiles?.map(profile => {
                                return <li>{profile.name} | {profile.range} | {profile.attacks} | {profile.skill} | {profile.strength} | {profile.ap} | {profile.damage}</li>
                              })
                            }
                          </ul></>) : (
                            <Form.Item valuePropName='checked' style={{marginBottom: 0}} name={'ranged_' + weapon.profiles[0].name}><Checkbox>{weapon.profiles[0].name} | {weapon.profiles[0].range} | {weapon.profiles[0].attacks} | {weapon.profiles[0].skill} | {weapon.profiles[0].strength} | {weapon.profiles[0].ap} | {weapon.profiles[0].damage}</Checkbox></Form.Item>
                          );
                    })
                  }
              </>
            }
            {
              unitToAdd.meleeWeapons && unitToAdd.meleeWeapons.length > 0 &&
              <>
                <p><strong>Select Melee Weapons</strong></p>
                {
                  unitToAdd.meleeWeapons?.map(weapon => {
                    return weapon.profiles.length > 1 ? (
                        <><Form.Item valuePropName='checked' style={{marginBottom: 0}} name={'melee_' + weapon.profiles[0].name.split('–')[0].split('-')[0]}><Checkbox>{weapon.profiles[0].name.split('–')[0].split('-')[0]}</Checkbox></Form.Item><ul>
                          {
                            weapon.profiles?.map(profile => {
                              return <li>{profile.name} | {profile.range} | {profile.attacks} | {profile.skill} | {profile.strength} | {profile.ap} | {profile.damage}</li>
                            })
                          }
                        </ul></>) : (
                          <Form.Item valuePropName='checked' style={{marginBottom: 0}} name={'melee_' + weapon.profiles[0].name}><Checkbox>{weapon.profiles[0].name} | {weapon.profiles[0].range} | {weapon.profiles[0].attacks} | {weapon.profiles[0].skill} | {weapon.profiles[0].strength} | {weapon.profiles[0].ap} | {weapon.profiles[0].damage}</Checkbox></Form.Item>
                        );
                  })
                }
              </>
            }
            {
              unitToAdd.points.length > 1 && <>
                <p><strong>Composition</strong></p>
                <ul>
                    {unitToAdd?.composition?.map(models => {
                      return <li>{models}</li>
                    })}
                </ul>
                <p><strong>Select Points</strong></p>
                <Form.Item name="points">
                  <Radio.Group>
                  {
                    unitToAdd.points?.sort(function(a, b) {
                      return Number(a.cost) - Number(b.cost);
                    }).map(point => {
                      return <li>
                          <Radio style={{marginBottom: 0}} value={'points_' + point.cost + '_' + point.models}>Models: {point.models}, Cost: {point.cost}</Radio>
                      </li>;
                    })
                  }
                  </Radio.Group>
                </Form.Item>
              </>
            }
            {
              unitToAdd?.wargear && unitToAdd?.wargear.length > 0 && unitToAdd?.wargear[0] !== 'None' &&
              <>
                <p><strong>Wargear</strong></p>
                <ul>
                  {
                    unitToAdd.wargear?.map(wargear => {
                      return <li>{wargear}</li>
                    })
                  }
                </ul>
                {
                  unitToAdd?.abilities?.wargear && unitToAdd?.abilities?.wargear.length > 0 &&
                  <>
                    <p><b>Select Wargear</b></p>
                    <ul>
                      {
                        unitToAdd.abilities.wargear?.map(ability => {
                          return <Form.Item  valuePropName='checked' name={`ability_${ability.name}`}><Checkbox><b>{ability.name}: </b><p> {ability.description}</p></Checkbox></Form.Item>
                        })
                      }
                    </ul>
                  </>
                }
              </>
            }
          </Form>
        </SelectionsContainer>
      }
    </Modal>
  );
};

const SelectionsContainer = styled.div`
`;

export default AddEditUnitModal;
