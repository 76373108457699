import React from 'react';
import { firebaseAuth } from '../firebase/firebaseConfig';

export const SignOutButton = () => {
  const signOut = () => {
    firebaseAuth.signOut();
  };

  return (
    <>
      <button onClick={signOut}>Sign Out</button>
    </>
  );
};
