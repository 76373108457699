import { HeaderBar } from './HeaderBar';

export const RootLayout = ({ children }) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw', overflow: 'hidden'}}>
      <HeaderBar/>
      <div style={{overflowY: 'auto'}}>
        {children}
      </div>
    </div>
  );
};
