import React, { useEffect, useState } from 'react';
import { Modal, Input, Select } from 'antd';
import { firebaseAuth, firestore } from '../../firebase/firebaseConfig';
import { factionChoices } from '../../utils/Factions';

const AddModelsModal = (props) => {
  const { open, closeModal, collectionKey } = props;
  const collectionsRef = firestore.collection('collections');
  const unitsRef = firestore.collection('units');
  const [selectedFaction, setSelectedFaction] = useState(null);
  const [modelsToAdd, setModelsToAdd] = useState([])
  const [modelOptions, setModelOptions] = useState([]);

  const updateModels = async (faction_id) => {
    const query = unitsRef
      .where('faction_id', '==', faction_id);
    const snapshot = await query.get();
    const compositions = snapshot.docs.map((doc) => (doc.data().composition.map(comp => comp.split('*')[0].split(',').map(s => s.replace('or:','').trim()))));
    const flatComps = compositions.flat(3);
    const cleanedComps = Array.from(new Set(flatComps.map((comp) => {
      return comp.replace(/^\d+(\-\d+)? /, '');
    })));
    setModelOptions(cleanedComps.map(val => ({"label": val, "value": val})));
  };
  useEffect(() => {
    updateModels(selectedFaction);
  }, [selectedFaction]);

  // TODO: Add code to handle the submission of the form.]

   const handleSubmit = () => {
    // TODO: Add code to create a collection with the given name.
    console.log(modelsToAdd);
    console.log(collectionKey);
    collectionsRef.doc(collectionKey).set({
      models: {
        [selectedFaction]: {...modelsToAdd.reduce((acc, item) => {
          acc[item] = 1;
          return acc;
        }, {})},
      }
    }, {merge: true});
    closeModal();
    clearModal();
  };

  const clearModal = () => {
    setSelectedFaction(null);
  }

  const handleFactionChanged = (value) => {
    setModelsToAdd([]);
    setSelectedFaction(value);
  }

  return (
    <Modal
      title="Create a collection"
      open={open}
      onCancel={() => { closeModal(); clearModal(); }}
      onOk={handleSubmit}
      okButtonProps={{disabled: modelsToAdd.length === 0}}
    >
      <Select
        showSearch
        optionFilterProp="label"
        placeholder="Select Faction"
        value={selectedFaction}
        onChange={handleFactionChanged}
        options={factionChoices.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        })}
        style={{width: '100%'}}
      />
      {
        modelOptions.length > 0 &&
        <Select
          mode="multiple"
          placeholder="Select Models"
          value={modelsToAdd}
          onChange={setModelsToAdd}
          options={modelOptions}
          style={{width: '100%'}}
        />
      }
    </Modal>
  );
};

export default AddModelsModal;
