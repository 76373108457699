import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Input, Select } from 'antd';
import { firebaseAuth, firestore } from '../../firebase/firebaseConfig';

const CreateMatchModal = (props) => {
  const {
    open,
    onClose,
    users,
    matches,
    handleCreateMatch,
  } = props;
  const [name, setName] = useState('');
  const [members, setMembers] = useState([firebaseAuth.currentUser.uid]);

  const handleSubmit = () => {
    if (!name || members.length < 2) {
      // TODO: add error handling
      return;
    }
    handleCreateMatch(name, members);
  };

  const cleanedUsers = useMemo(() => {
    if (!users) return [];
    return users.map((user) => {
      if (!user.displayName) {
        return {
          label: 'Anonymous',
          value: user.uid,
        };
      }
      return {
        label: user.displayName,
        value: user.uid,
      };
    }).sort((a, b) => a.label.localeCompare(b.label));
  }, [users]);

  return (
    <Modal
      title="Create a match"
      open={open}
      onCancel={() => {onClose?.(); setName(''); setMembers([firebaseAuth.currentUser.uid]);}}
      onOk={handleSubmit}
    >
      <Input
        placeholder="Enter a name for your match"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Select
        showSearch
        mode="multiple"
        optionFilterProp="label"
        placeholder="Select players"
        value={members}
        onChange={(value) => {
            setMembers(value);
        }}
        options={cleanedUsers}
        style={{ width: '100%', marginTop: '1rem' }}
      />
    </Modal>
  );
};

export default CreateMatchModal;
