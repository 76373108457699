import { useLocation, useNavigate } from "react-router-dom";
import { SignOutButton } from '../SignOutButton';

import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebase/firebaseConfig";
import styled from "styled-components";
import { getPageTitle } from "../../utils/NavUtils";

export const HeaderBar = () => {
    // get the current route using react-router-dom hook
    const navigate = useNavigate();
    const location = useLocation();
    const pageName = getPageTitle(location.pathname);
    const [user] = useAuthState(firebaseAuth);
    return (
      <Header>
        { location.pathname !== '/' && <button style={{position: 'absolute', left: '16px'}} onClick={() => navigate(-1)}>Back</button> }
        <Title>{pageName}</Title>
        {user && <div style={{position: 'absolute', right: '16px', display: 'flex', alignItems: 'center'}}><SignOutButton /></div>}
      </Header>
    )
  }

  const Header = styled.div`
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #424242;
    min-height: 76px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  `;

  const Title = styled.span`
    font-size: 32px;
    @media (min-width: 768px) {
      font-size: 48px;
    }
    @media (max-width: 500px) {
      font-size: 24px;
    }
    font-weight: bold;
    color: white;
  `;