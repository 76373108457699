// boilerplate react component
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firebaseAuth, firestore } from '../../firebase/firebaseConfig';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import AddEditUnitModal from './AddEditUnitModal';
import { getAlignedFactions } from '../../utils/Factions';
import styled from 'styled-components';
import { UnitCardSmall } from '../SharedComponents/UnitCardSmall';
import { UnitCardModal } from '../SharedComponents/UnitCardModal';

export const ListViewer = () => {
  const { listId } = useParams();
  const [addEditModalOpen, setAddEditModalOpen] = useState(false);
  const [editUnitDef, setEditUnitDef] = useState(null);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [previewUnit, setPreviewUnit] = useState({});

  const uid = firebaseAuth.currentUser.uid;
  const listsRef = firestore.collection('lists');
  // TODO: replace enhancements collection with existing data in faction collection
  const enhacementsRef = firestore.collection('enhancements');
  const listQuery  = listsRef.where('id', '==', listId + ';' + uid);
  const [listMatch] = useCollectionData(listQuery);
  const list = listMatch?.[0];
  let factions = [];
  let mainFactionId;
  if (list?.faction) {
    factions = [list.faction, ...getAlignedFactions(list?.faction)];
    mainFactionId = list.faction;
  }
  const unitsRef = firestore.collection('units');
  const unitsQuery = factions.length > 0 ? unitsRef.where('faction_id', 'in', factions) : null;
  const [units] = useCollectionData(unitsQuery);
  const enhancementsQuery = mainFactionId ? enhacementsRef.where('faction_id', '==', mainFactionId) : null;
  const [enhancements] = useCollectionData(enhancementsQuery);

  const handleOpenModal = (unitToEdit) => {
    setEditUnitDef(unitToEdit);
    setAddEditModalOpen(true);
  };

  const handleAddUnit = (unit, selections) => {
    const unitToAdd = {
      unit_id: unit.faction_id + ';' + unit.name,
      unique_id: crypto.randomUUID(),
      name: unit.name,
      faction_id: unit.faction_id,
      selections: selections,
    };
    listsRef.doc(listId + ';' + uid).set({
      units: {
        ...(list?.units || {}),
        [unitToAdd.unique_id]: unitToAdd,
      }
    }, {merge: true});
  };

  const handleEditUnit = (unit, selections, unique_id) => {
    const unitToUpdate = {
      unit_id: unit.faction_id + ';' + unit.name,
      unique_id: unique_id,
      name: unit.name,
      faction_id: unit.faction_id,
      selections: selections,
    };
    listsRef.doc(listId + ';' + uid).set({
      units: {
        ...(list?.units || {}),
        [unique_id]: unitToUpdate,
      }
    }, {merge: true});
  }

  const deleteUnit = (unit_id) => {
    const newUnits = list?.units || {};
    delete newUnits[unit_id];
    listsRef.doc(listId + ';' + uid).set({
      ...list,
      units: {
        ...newUnits,
      }
    });
  }

  const cost = list?.units ? Object.values(list.units).reduce((acc, unit) => {
    return acc + Number(unit.selections.cost) + Number(unit?.selections?.enhancement?.cost || 0);
  }, 0) : 0;

  const openPreview = (unit, unitDef) => {
    setPreviewUnit({
      selections: unit.selections,
      unit: unitDef,
    });
    setPreviewModalOpen(true);
  }

  return (
    <div>
      <AddEditUnitModal
        isAddModal={!editUnitDef}
        editUnitDef={editUnitDef}
        open={addEditModalOpen}
        closeModal={() => {
          setEditUnitDef(null);
          setAddEditModalOpen(false);
        }}
        units={units}
        enhancements={enhancements?.[0]?.enhancements}
        addUnitFunc={handleAddUnit}
        editUnitFunc={handleEditUnit}
        mainFactionId={mainFactionId}
      />
      <UnitCardModal
        open={previewModalOpen}
        closeModal={() => {
          setPreviewModalOpen(false);
        }}
        unit={previewUnit.unit}
        selections={previewUnit.selections}
      />
      {}
      <h1>{list?.name} | {cost} points</h1>
      <button onClick={() => { handleOpenModal(); }}>Add Unit</button>
      <h2>Units:</h2>
      {
        list?.units && 
        <UnitsContainer>
          {
            Object.values(list.units).sort(function(a, b) {
              return a.name.localeCompare(b.name);
            }).map((unit) => {
              return (
                <UnitCardSmall
                  key={unit.unique_id}
                  unit={unit}
                  deleteUnit={deleteUnit}
                  handleEditUnit={handleOpenModal}
                  handleOpenPreview={() => { openPreview(unit, units.find(u => u.faction_id + ';' + u.name === unit.unit_id)); }}
                />
                // <UnitDisplay key={unit.unique_id}>
                //   <h3>{unit.name} | {(Number(unit.selections.cost) + Number(unit?.selections?.enhancement?.cost || 0))} pts</h3>
                //   {/* {
                //     unit.selections.rangedWeapons &&
                //     <div>
                //       <p>Ranged Weapons:</p>
                //       <p>{unit.selections.rangedWeapons.join(', ')}</p>
                //     </div>
                //   }
                //   {
                //     unit.selections.meleeWeapons && unit.selections.meleeWeapons.length > 0 &&
                //     <div>
                //       <p>Melee Weapons:</p>
                //       <p>{unit.selections.meleeWeapons.join(', ')}</p>
                //     </div>
                //   } */}
                //   <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                //     <button onClick={() => handleOpenModal(unit)}>Edit</button>
                //     <button onClick={() => deleteUnit(unit.unique_id)}>Delete</button>
                //   </div>
                // </UnitDisplay>
              )
            })
          }
        </UnitsContainer>
      }
    </div>
  );
};

const UnitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const UnitDisplay = styled.div`
  background: linear-gradient(300deg, rgba(171, 171, 171, 0.75) 26.67%, rgba(0, 0, 0, 0.50) 100%);
  padding: 4px;
  margin: 8px;
  font-size: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
`;