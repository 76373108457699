import logo from './logo.svg';
import './App.css';

import { useAuthState } from 'react-firebase-hooks/auth';
import { SignInWithGoogle } from './components/SignInWithGoogle';
import { Home } from './components/Home';
import { CollectionRouter } from './components/Collections/CollectionRouter';
import { BrowserRouter, Route, Routes, useNavigation } from 'react-router-dom';
import { GamesRouter } from './components/Games/GamesRouter';
import { HeaderBar } from './components/Layout/HeaderBar';
import { RootLayout } from './components/Layout/RootLayout';
import { firebaseAuth, firestore } from './firebase/firebaseConfig';
import AddUnit from './components/ManageData/AddUnit';
import { ListsRouter } from './components/Lists/ListsRouter';
import { ManageDataHome } from './components/ManageData/ManageDataHome';
import AddEnhancements from './components/ManageData/AddEnhancements';
import { useEffect } from 'react';

function App() {
  const [user] = useAuthState(firebaseAuth);
  const usersRef = firestore.collection('users');
  // if a new user logs on, add them to the users collection
  useEffect(() => {
    if (user) {
      const userDoc = usersRef.doc(user.uid);
      userDoc.get().then((doc, err) => {
        console.log(doc.exists, err);
        if (doc.exists === false || err) {
          userDoc.set({
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            uid: user.uid,
            isActive: true,
            createdAt: firebaseAuth.currentUser.metadata.creationTime,
          });
        }
        else {
          console.log(doc.data());
        }
      });
    }
  }, [user]);
  return (
    <div className="App">
      <BrowserRouter>
        <RootLayout>
          {
            user ?
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/collection/*" element={<CollectionRouter />} />"
                <Route path="/matches/*" element={<GamesRouter />} />
                <Route path="/lists/*" element={<ListsRouter />} />
                <Route path="/manageData" element={<ManageDataHome />} />
                <Route path="/manageUnitsData" element={<AddUnit />} />
                <Route path="/manageEnhancementsData" element={<AddEnhancements />} />
              </Routes>
            : <SignInWithGoogle />
          }
        </RootLayout>
      </BrowserRouter>
    </div>
  );
}

export default App;
